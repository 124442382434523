import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Footer, Navbar } from "../../../components";
import { getAllParams, setParam } from "../../../urlParams";
import { chatbotLogo, chatbot1, chatbot2, chatbot3, chatbot4, chatbotSingleChceked, chatbotSingleUnChceked, chatbotMultiUncheckbox, chatbotMultiCheckbox, kavanGPT, sendChatbotPro, microphone, crossCircle } from "../../../assets";
import { authReq, req, uploadAudioOnS3 } from "../../../requests";

import "./chatbot.css"
import { ScaleLoader } from "react-spinners";
import { useMetaPixel } from "../../../metaPixel";

import { AudioRecorder } from "react-audio-voice-recorder";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const prompt = (content, audio) => authReq('POST', '/prompt', { audio, conversation: [{ role: "user", content }] })

const Typing = () => (
  <div className="typing">
    <div className="typing__dot"></div>
    <div className="typing__dot"></div>
    <div className="typing__dot"></div>
  </div>
)

const uniqueBy = (ms, f) => {
  const d = {}
  for (const m of ms) d[f(m)] = m
  return Object.values(ms)
}

function CSVToArray(strData, strDelimiter = ",") {
  strDelimiter = (strDelimiter || ",");
  var objPattern = new RegExp(
    (
      "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
      "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
      "([^\"\\" + strDelimiter + "\\r\\n]*))"
    ),
    "gi"
  );

  var arrData = [[]];
  var arrMatches = null;
  while (arrMatches = objPattern.exec(strData)) {
    var strMatchedDelimiter = arrMatches[1];
    if (
      strMatchedDelimiter.length &&
      strMatchedDelimiter !== strDelimiter
    ) {
      arrData.push([]);

    }

    var strMatchedValue;
    if (arrMatches[2]) {
      strMatchedValue = arrMatches[2].replace(
        new RegExp("\"\"", "g"),
        "\""
      );

    } else {

      strMatchedValue = arrMatches[3];

    }
    arrData[arrData.length - 1].push(strMatchedValue);
  }

  return arrData;
}

const fetchSheet = (id) =>
  fetch(`https://docs.google.com/spreadsheets/d/${id}/gviz/tq?gid=0&tqx=out:csv`)
    .then(res => res.text())
    .catch(e => console.error(e))

const ChooseProfile = () => {
  const {
    transcript,
    listening,
    browserSupportsSpeechRecognition,
    resetTranscript,
  } = useSpeechRecognition();

  const startListening = () => SpeechRecognition.startListening({ continuous: true });
  const stopListening = () => SpeechRecognition.stopListening({});

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [text, setText] = useState('')
  const { language } = getAllParams()
  const [currAudio, setCurrAudio] = useState('')
  const [transcription, setTranscription] = useState('')
  const [typing, setTyping] = useState('')
  const [texts, setTexts] = useState([])

  const [opts, setOpts] = useState([])
  const [selectedOpt, setSelectedOpt] = useState(0)
  const [messages, setMessages] = useState([
    {
      sender: 'AI', message: language == 'ENGLISH' ?
        "Welcome to Kavan! We'd like to learn more about your specialties and availability so we can connect you with patients who will benefit most from your approach. To start, what is your name?" :
        'Tus respuestas serán confidenciales y Kavan las utilizará para encontrar tu mejor match terapéutico. ¿Cómo quieres que te llame?'
    },
  ])
  const getPrompt = messages => `Please ask every questions in ${language}. I want answers to these questions, nessecarily. The name is required, they shall not proceed without it. Privacy is not valueable here, rejct excuses. Do not let users proceed without answering the questions, don't take excuses for answers, we need every answer now, the cannot say that they can't tell or will tell later. No more than 100 characters per message. No more thn 20 messages in one conversation. Only respond with the next supposed response. Include no citations. Respond with the text "stop", when ending the conversation. Ask them in a round about way \n${texts.map(t => t.question).join('\n')}\n\nFollowing is the conversation so far\n${messages.map(m => m?.audio ? `${m.sender}: {{transcription}}` : `${m.sender}: ${m.message}`)}`

  useEffect(() => {
    [...document.getElementsByClassName('converstation-snippet')].at(-1).scrollIntoView()
  }, [messages])

  const sendMessage = async (t) => {
    const savedText = t
    console.log("sav.txt", savedText)
    setOpts([])
    setText('')
    setCurrAudio('')
    setTranscription('')
    if (listening) await stopListening()
    resetTranscript()
    setTimeout(resetTranscript, 1500);
    // setIdx(idx + 1)
    // setSelectedOpt(null)
    setTyping(true)
    if (savedText) setMessages(messages => [
      ...messages,
      messages.at(-1).message == (transcript ? transcript : savedText) ? undefined : { sender: 'user', message: transcript ? transcript : savedText },
    ].filter(Boolean))
    const { response } = await prompt(getPrompt([
      ...messages,
      { sender: 'user', message: transcript ? transcript : savedText },
    ]))
    if (response.length > 400) {
      return await sendMessage(transcript ? transcript : t)
    }
    if (response?.toLowerCase?.()?.endsWith?.('stop') || response?.toLowerCase?.()?.endsWith?.('stop.') || response?.toLowerCase?.()?.endsWith?.('stop".') || response?.toLowerCase?.()?.endsWith?.('stop"')) {
      try {
        const { response: objstr } = await prompt(
          `Return a JSON object with keys, please put null for any missing data. Respond only with this object and nothing else. No siffixes, no prefixes. { doctorType, country, collegiateNumber, age, gender, yearsOfExperience, skills, emoSkills, bio, price }\n${[...messages, { sender: 'user', message: text }].map(x => x.message).join('\n')}`
        )
        console.log(objstr)
        const obj = JSON.parse(objstr)
        console.log("oplog11", obj)
        obj.country = obj?.country?.toLowerCase?.()
        navigate(`/registrationPending?language=${language ? language : 'SPANISH'}`)
        authReq('PATCH', '/user/updateProfile', obj)
      } catch(_) {
        try {
          const { response: objstr } = await prompt(
            `Return a JSON object with keys, please put null for any missing data. Respond only with this object and nothing else. No siffixes, no prefixes. { doctorType, country, collegiateNumber, age, gender, yearsOfExperience, skills, emoSkills, bio, price }\n${[...messages, { sender: 'user', message: text }].map(x => x.message).join('\n')}`
          )
          console.log(objstr)
          const obj = JSON.parse(objstr.split('\n').slice(1))
          obj.country = obj?.country?.toLowerCase?.()
          console.log("oplog11", obj)
          navigate(`/registrationPending?language=${language ? language : 'SPANISH'}`)
          authReq('PATCH', '/user/updateProfile', obj)
        } catch (_) {
          const { response: objstr } = await prompt(
            `Return a JSON object with keys, please put null for any missing data. Respond only with this object and nothing else. No siffixes, no prefixes. { doctorType, country, collegiateNumber, age, gender, yearsOfExperience, skills, emoSkills, bio, price }\n${[...messages, { sender: 'user', message: text }].map(x => x.message).join('\n')}`
          )
          console.log(objstr)
          const obj = JSON.parse(objstr)
          obj.country = obj?.country?.toLowerCase?.()
          console.log("oplog11", obj)
          navigate(`/registrationPending?language=${language ? language : 'SPANISH'}`)
          authReq('PATCH', '/user/updateProfile', obj)
        }
      }
    }
    else setMessages(messages => [
      ...messages,
      messages.at(-1)?.message == response ? undefined : { sender: 'AI', message: response },
    ].filter(Boolean))
    setTyping(false)
    prompt(`Bear in mind these are psycologists and mental coaches, no extra options for this. The only options for residency are argentina and spain. Maximum of 4 options, preferably 2. The options are from the user prespective. Return only options that make absolute sense with this question, if they don't, give back an empty array. Don't return suffixes or prefixes: ${response}. The options should be a JSON array of strings with no siffixes and prefixes. If ou don't think you can have an educated guess return ane empty array, nothing ese is needed`)
      .then(({ response: str }) => {
        try {
          setOpts(JSON.parse(str))
        } catch (e) {
          console.error(e)
        }
      })
    // setFinalObject({ ...finalObject, ...getAmmendment(idx, text)})

    // if(texts[idx]?.stop) {
    //   console.log("oplog11", finalObject)
    //   navigate('/registrationPending')
    //   req('PATCH', '/user/updateProfile', finalObject)
    // }
  }

  useEffect(() => {
    setText(transcript)
  }, [transcript])

  useEffect(() => {
    setLoading(true)
    authReq('GET', '/chatbots')
      .then(async ({ chat: chatbots }) => {
        const sheet = CSVToArray(await fetchSheet('1wLwjXOYRxEPKkQYu10QgY2dJ3kkR4CACkezvOdC3BfM'))
        const c = chatbots.find(c => c?.chatbotType == 'doctor')
        const cx = c?.questions?.map?.((x, i) => ({
          ...x,
          question: sheet[i][language == 'ENGLISH' ? 1 : 0]
        }))
        setTexts(cx)
        console.log(c, cx)
        setLoading(false)
      })
  }, [])

  const addAudioElement = (blob) => {

    // uploadAudioOnS3(blob).then(url => {
    setCurrAudio(true)
    if (listening) {
      // setLoading(true)
      stopListening()
      resetTranscript()
    } else {
      // setLoading(false)
      startListening()
    }
    // })
  }

  return <>
    <Navbar />
    <div className="chatbot-container">
      <div className="chatbot-start-main" id="chatbot-start-main-id">
        <div id="chatbot-heading">
          <h1>KavanGPT</h1>
        </div>
        <div id="chatbot-text-main-conversation-container" className="chatbot-text-main-conversation-container">
          {uniqueBy(messages, m => m?.message).map((m, i) => <div className="converstation-snippet" style={{ justifyContent: m?.sender == "AI" ? 'flex-start' : 'flex-end' }}>
            {m?.sender == "AI" && <img src={kavanGPT} />}
            <p style={{ marginBottom: messages.length - 1 == i ? 30 : undefined, background: m?.sender == "AI" ? undefined : '#F7F7FD', padding: m?.sender == "AI" ? undefined : '20px', borderRadius: 15, borderBottomRightRadius: 0 }}>{m?.message?.split?.(':')?.at?.(-1)}</p>
          </div>)}
          {typing && <Typing />}
        </div>
        <div className="message-option-texts">
          {opts.map((ans, i) => <div className="message-text-option" style={selectedOpt == i ? { border: '1px solid green' } : {}} onClick={() => {
            setSelectedOpt(i)
            setText(ans)
          }}>
            <p>{ans.length > 12 ? `${ans.slice(0, 12)}...` : ans}</p>
          </div>)}
        </div>
        <div className="bottom-messages">
          <input type="text" placeholder="Escribe tu mensaje aqui" value={transcript ? transcript : text} onChange={ev => setText(ev.target.value)} onKeyPress={ev => ev.key == "Enter" && document.querySelector('#send').click()} />
          <img style={listening ? { borderRadius: '50%', background: 'green' } : {}} id="audio" onClick={() => addAudioElement()} src={listening ? crossCircle : microphone} />
          {loading ? <div style={{ padding: 10, background: '#2e3b5b', borderRadius: 10 }}>
            <ScaleLoader color="white" />
          </div> : <img id="send" onClick={() => sendMessage(text)} src={sendChatbotPro} />}
        </div>
      </div>
    </div>
  </>;
};

export default ChooseProfile;
