import { baseURL } from './constants';

export const req = async (
  method,
  endpoint,
  body = null,
  onError = () => {},
  onSuccess = () => {}
) => {
  try {
    const res = await fetch(`${baseURL}${endpoint}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: body ? JSON.stringify(body) : null,
    });
    const data = await res.json();
    console.log('HAHAHAH', data);
    if (data.success) {
      console.log('Here!');
      onSuccess(data.data);
    } else onError(data.message);
    return data.data;
  } catch (err) {
    onError(err);
    console.log(err);
  }
};

const refresh = async () => {
  const refreshToken = localStorage.getItem('kawan_refreshToken');
  if (!refreshToken) throw 'No Refresh Token Found';
  const data = await req('POST', `/user/refresh/${refreshToken}`, {
    device: {
      id: 'web',
      deviceToken: 'MockToken',
    },
  });
  console.log('Refresh Token', data);
  if (data.refreshToken)
    localStorage.setItem('kawan_refreshToken', data.refreshToken);
  localStorage.setItem('kawan_accessToken', data.accessToken);
  return data.accessToken;
};

export const authReq = async (
  method,
  endpoint,
  body = null,
  onError = () => {}
) => {
  console.log('Calling Auth Req');
  try {
    let authToken = localStorage.getItem('kawan_accessToken');
    if (!authToken) {
      const { kawan_accessToken } = Object.fromEntries(
        document.cookie
          .split(';')
          .map((str) => str.split('=').map((x) => x.trim()))
      );
      if (kawan_accessToken) {
        localStorage.setItem('kawan_accessToken', kawan_accessToken);
        authToken = kawan_accessToken;
      }
    }
    console.log(authToken);
    console.log('DATA LOG 0', {});
    const res = await fetch(`${baseURL}${endpoint}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: authToken,
      },
      body: body ? JSON.stringify(body) : undefined,
    });
    console.log('DATA LOG 1', res);
    const data = await res.json();
    // if((data.message == "jwt expired" || data.message == "jwt malformed") && count < 2) {
    //     console.log("Attempting Refresh")
    //     await refresh()
    //     return await authReq(method, endpoint, body, count+1)
    // }
    console.log('DATA LOG 2', data);
    if (!data.success) onError(data.message);
    else return data.data;
  } catch (err) {
    console.log('Hi!Hi!');
    onError(err?.message);
    // if(count < 2) {
    //     console.log("Attempting Refresh")
    //     await refresh()
    //     return await authReq(method, endpoint, body, count+1)
    // }
    console.log(err);
  }
};

const uploadImageOnS3 = async (src, cb) => {
  return new Promise((resolve, reject) => {
    try {
      const formData = new FormData()
      formData.append('file', src)
      fetch('https://api.kavanhealth.com/upload', { method: 'POST', body: formData })
        .then(res => res.json())
        .then(data => resolve(data.Location))
    } catch (error) {
      console.error('Error uploading to S3:', error);
      reject(error);
    }
  });
};

export const uploadAudioOnS3 = src => new Promise((resolve, reject) => {
  try {
    const formData = new FormData()
    formData.append('file', src)
    fetch('https://api.kavanhealth.com/upload-audio', { method: 'POST', body: formData })
      .then(res => res.json())
      .then(data => resolve(data.Location))
  } catch (error) {
    console.error('Error uploading to S3:', error);
    reject(error);
  }
});

export const upload = (cb) => (evt) => {
  const files = evt.target.files;
  const file = files[0];
  uploadImageOnS3(file, cb)
    .then((url) => {
      cb(url);
    })
    .catch((error) => console.log('error', error));
};
