import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { clockWithDots } from "../../../assets";
import { Button, Footer, Navbar } from "../../../components";
import { getAllParams } from "../../../urlParams";
import { authReq } from "../../../requests";
import "./registrationPending.css";

const translate = lang => (s) => {
  const obj = {
    ENGLISH: {
      "Estamos verificando su cuenta": "We are verifying your account",
      "Una vez que un administrador haya revisado su perfil, podrá tener acceso": "Once an administrator has reviewed your profile, you will be granted access.",
      "Volver a ianiciar sesion": "Go back to log in",
    },
    SPANISH: {
      "Estamos verificando su cuenta": "Estamos verificando su cuenta",
      "Una vez que un administrador haya revisado su perfil, podrá tener acceso": "Una vez que un administrador haya revisado su perfil, podrá tener acceso",
      "Volver a ianiciar sesion": "Volver a ianiciar sesion",
    }
  }
  return obj[lang][s]
}


const RegistrationPending = () => {

  const obj = JSON.parse(JSON.stringify(getAllParams()));
  useEffect(() => {
    // const obj = JSON.parse(JSON.stringify(getAllParams()));
    const keys = Object.keys(obj);
    console.log("OBJECT =>", obj);
    if (keys.length > 0)
      authReq("PATCH", "/user/updateProfile", obj).then((_) => console.log(_));
  }, []);

  const navigate = useNavigate();
  const t = translate(obj?.language)

  return (
    <div>
      <Navbar />
      <div className="kwn-fill_out_form-main_container">
        <div className="kwn-fill_out_form-title_container">
          <div className="kwn-reg_pending-title_container">
            {/* <img alt="" src={clockWithDots} /> */}
          </div>
          <h1>{t('Estamos verificando su cuenta')}</h1>
          <h2>{t('Una vez que un administrador haya revisado su perfil, podrá tener acceso')}</h2>
        </div>
        {/* <Button onClick={() => navigate("/registrationSuccessfull")}>
          Next
        </Button> */}
        <div
          onClick={() => window.location.href = "https://www.kavanhealth.com/log-in"}
          className="kwn-create_profile-add_later"
        >
          {/* <Button> */}
          <p style={{ textDecoration: 'underline', fontSize: 16 }} onClick={() => navigate('/dashboard/AppointmentStack')}>
            {t('Volver a ianiciar sesion')}
          </p>
          {/* </Button> */}
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default RegistrationPending;
